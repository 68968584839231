import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// // 解决报错
// const originalPush = VueRouter.prototype.push
// const originalReplace = VueRouter.prototype.replace
// // push
// VueRouter.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }
// // replace
// VueRouter.prototype.replace = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
//   return originalReplace.call(this, location).catch(err => err)
// }

const routes = [
	{
		path: '/',
		redirect: '/Menu',
	},
	{
		path: '/Menu',
		component: () => import('../components/Menu.vue'),
		children: [
			{
				path: '/',
				redirect: '/home',
			},
			{
				path: '/home', //首页
				name: '/home',
				component: () => import('../views/home/Home.vue'),
			},
			{
				path: '/solution', //解决方案
				name: '/solution',
				component: () => import('../views/solution/solution.vue'),
			},
			{
				path: '/product', //产品中心
				name: '/product',
				component: () => import('../views/product/product.vue'),
			},
			{
				path: '/experience', //体验中心
				name: '/experience',
				component: () => import('../views/experience/experience.vue'),
				children: [
					{
						path: '/',
						redirect: '/asr&vs',
					},
					{
						path: '/asr&vs',
						name: '/asr&vs',
						component: () =>
							import('../views/experience/childCpn/asr&vs.vue'),
					},
					{
						path: '/translate',
						name: '/translate',
						component: () =>
							import(
								'../views/experience/childCpn/translate.vue'
							),
					},
					{
						path: '/ocr',
						name: '/ocr',
						component: () =>
							import('../views/experience/childCpn/ocr.vue'),
					},
					{
						path: '/voiceAnalyze',
						name: '/voiceAnalyze',
						component: () =>
							import(
								'../views/experience/childCpn/voiceAnalyze.vue'
							),
					},
					{
						path: '/more',
						name: '/more',
						component: () =>
							import('../views/experience/childCpn/more.vue'),
					},
				],
			},
			{
				path: '/aboutUs', //关于我们
				name: '/aboutUs',
				component: () => import('../views/aboutUs/AboutUs.vue'),
				children: [
					{
						path: '/',
						redirect: '/companyOf',
					},
					{
						path: '/companyOf',
						name: '/companyOf',
						component: () =>
							import('../views/aboutUs/company/index.vue'),
					},
					{
						path: '/newsAndTrends',
						name: '/newsAndTrends',
						component: () =>
							import('../views/aboutUs/news/index.vue'),
					},
					{
						path: '/newsDetails',
						name: '/newsDetails',
						component: () =>
							import(
								'../views/aboutUs/news/childCpn/newsDetails.vue'
							),
					},
				],
			},
			{
				path: '/experienceDemo', //移动端体验中心
				name: '/experienceDemo',
				component: () =>
					import('../views/experience/experienceDemo.vue'),
			},
		],
	},
	{
		path: '/formSub', //表单信息提交
		name: '/formSub',
		component: () => import('../components//formSub.vue'),
	},
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/** 解决跳转重复路由报错问题 */
const routerPush = VueRouter.push
VueRouter.push = (path) => {
	// 判断下当前路由是否就是要跳转的路由
	if (VueRouter.currentRoute.fullPath.includes(path)) return
	return routerPush.call(VueRouter, path)
}

const router = new VueRouter({
	mode: 'hash',
	// base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0,
			}
		}
	},
})

export default router
