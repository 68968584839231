import Vue from 'vue'
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import './auto-import-elementUI.js'
import normalize from 'normalize.css'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utils/axios'

//引入字体包
import './assets/fonts/font-text.css'

import VueQuillEditor from 'vue-quill-editor'
import { Quill } from 'vue-quill-editor'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { ImageDrop } from 'quill-image-drop-module'
Quill.register('modules/imageDrop', ImageDrop)
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)

import Recorder from 'recorder-core'

// import "normalize.css";

// Vue.use(ElementUI);
Vue.use(VueQuillEditor)
Vue.use(normalize)

//图片懒加载插件
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
// 配置项
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: require('./assets/img/error.png'),
	loading: require('./assets/img/loading.webp'),
	attempt: 1,
})
//预览图片
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
	Options: {
		inline: true, //启用inline模式默认false
		button: true, //显示右上角关闭按钮
		navbar: true, //显示底部缩略图
		title: true, //显示当前图片标题
		toolbar: true, //底部工作栏
		tooltip: true, //显示缩放百分比
		movable: true, //图片是否可移动
		zoomable: true, //图片是否可缩放
		rotatable: true, //图片是否可以旋转
		scalable: true, //图片是否可以翻转
		transition: true, //使用css3过渡
		fullscreen: true, //播放时是否全屏
		keyboard: true, //是否支持键盘
		url: 'data-source',
	},
})

import WaveSurfer from 'wavesurfer'

import html2canvas from 'html2canvas'
import { message } from '@/resetMessage'

// 过滤方法文件
import './utils/filter'

Vue.config.productionTip = false

Vue.prototype.WaveSurfer = WaveSurfer
Vue.prototype.html2canvas = html2canvas
Vue.prototype.$message = message
Vue.prototype.axios = axios
Vue.prototype.Recorder = Recorder

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
