import Vue from 'vue';
import store from '../store';

Vue.filter('timeDateFormat', (val) => {
  if (!val) return '--';
  let time = new Date(val),
    year = time.getFullYear(),
    month = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1,
    day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
  return year + '-' + month + '-' + day
})
Vue.filter('dateTimeFormat', (val) => {
  if (!val) return '--';
  let time = new Date(val),
    year = time.getFullYear(),
    month = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1,
    day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate(),
    hour = time.getHours() < 10 ? '0' + time.getHours() : time.getHours(),
    min = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes(),
    second = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
  return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
})

Vue.filter('dateNameFormat', (val) => {
  if (!val) return '--';
  let time = new Date(val),
    year = time.getFullYear(),
    month = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1,
    day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate(),
    hour = time.getHours() < 10 ? '0' + time.getHours() : time.getHours(),
    min = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
  // second = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
  return year + '年' + month + '月' + day + '日' + hour + ':' + min;
})

// 时间由秒转为 00：00：00
Vue.filter('timeFormat', (val) => {
  if (!val) return '00:00:00';
  let NowtimeValue = val;
  let nowH = parseInt(NowtimeValue / 3600);
  let nowM = parseInt(NowtimeValue % 3600 / 60);
  let nowS = parseInt(NowtimeValue % 60);

  nowH < 10 && (nowH = "0" + nowH);
  nowM < 10 && (nowM = "0" + nowM);
  nowS < 10 && (nowS = "0" + nowS);
  return nowH + ":" + nowM + ":" + nowS
})

Vue.filter('dateLengthFormat', (val) => {
  if (!val) return '--';
  let time = new Date(val).getTime(),
    day = Math.floor(time / 1000 / 3600 / 24),
    ceil1 = time / 1000 / 3600 / 24 - day,
    hour = Math.floor(ceil1 * 24),
    ceil2 = ceil1 * 24 - hour,
    min = Math.floor(ceil2 * 60),
    ceil3 = ceil2 * 60 - min,
    second = Math.floor(ceil3 * 60);
  return day + '天' + hour + '小时' + min + '分钟' + second + '秒'
})
Vue.filter('mounthFomat', (val) => {
  if (!val) return '--';
  let time = new Date(val),
    // year = time.getFullYear(),
    month = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1,
    day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate(),
    hour = time.getHours() < 10 ? '0' + time.getHours() : time.getHours(),
    min = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes(),
    second = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
  return month + '/' + day + ' ' + hour + ':' + min + ':' + second

})

Vue.filter('timeFormatString', (val) => {
  const date = val.replace('T', ' ');
  return date;
})
Vue.filter('filterStatus', (val) => {
  switch (val) {
    case 0:
      return '未处理';
    case 1:
      return '已确认';
    case 2:
      return '已忽略';
    default:
      break;
  }
})

Vue.filter('filterHomeStatus', (val) => {
  switch (val) {
    case 0:
      return '未使用';
    case 1:
      return '监控中';
    case 2:
      return '监控故障';
    default:
      break;
  }
})

Vue.filter('searchplanId', (val) => {
  let planName = '';
  store.state.planList.forEach(item => {
    if (item.planId == val) {
      planName = item.planName;
    }
  })
  return planName;
})

