<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'app',
	computed: {
		// clientHeight: {
		//   get() {
		//     return this.$store.state.clientHeight;
		//   },
		//   set(val) {
		//     this.$store.commit("setClientHeight", val);
		//   },
		// },
		// clientWidth: {
		//   get() {
		//     return this.$store.state.clientWidth;
		//   },
		//   set(val) {
		//     this.$store.commit("setClientWidth", val);
		//   },
		// },
	},
	methods: {
		saveStore() {
			if (sessionStorage.getItem('store')) {
				this.$store.replaceState(
					Object.assign(
						{},
						this.$store.state,
						JSON.parse(sessionStorage.getItem('store'))
					)
				)
				sessionStorage.setItem('store', '')
			}
			// this.setHeight();
			window.addEventListener('beforeunload', () => {
				sessionStorage.setItem(
					'store',
					JSON.stringify(this.$store.state)
				)
			})
		},
		setHeight() {
			this.clientHeight =
				window.innerHeight ||
				document.documentElement.clientHeight ||
				document.body.clientHeight
			this.clientWidth =
				window.innerWidth ||
				document.documentElement.clientWidth ||
				document.body.clientWidth
			if (window.screen.width <= 768) {
				this.$store.state.isMobileEnd = true
			} else {
				this.$store.state.isMobileEnd = false
			}
		},
	},
	mounted() {
		window.onresize = () => {
			return (() => {
				this.setHeight()
			})()
		}
	},
	created() {
		this.saveStore()
	},
}
</script>

<style lang="scss">
#app {
	font-family: Source Han Sans, Source Han Sans CN;
	// min-width: 1180px;
	margin: 0 auto;
}
::-webkit-scrollbar {
	width: 10px; /*滚动条宽度*/
	height: 10px; /*滚动条高度*/
}

::-webkit-scrollbar-track {
	// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 5px; /*滚动条的背景区域的圆角*/
	// background: #efefef;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px; /*滚动条的圆角*/
	// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background: #c7c9cd;
}
::-webkit-scrollbar-button {
	display: none;
}
a {
	text-decoration: none;
}
.el-submenu__title .el-submenu__icon-arrow::before {
	color: #ffffff !important;
}
.is-opened .el-submenu__title .el-submenu__icon-arrow::before,
.is-active .el-submenu__title .el-submenu__icon-arrow::before {
	color: #127bf2 !important;
}
.el-menu--popup {
	border-radius: 6px !important;
	border: 1px solid #0078ff;
}
.el-popper .popper__arrow {
	border-bottom-color: transparent !important;
	&::after {
		display: none;
	}
}
</style>
